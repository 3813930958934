<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{
    'form-commercial-operation.statusChangeForSelectedInvoices' | translate
  }}"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getData()"
  (onInit)="onInit($event)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="readOnly"
        (onClick)="onSave()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#choosed' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <form [formGroup]="form">
        <div class="form-columns">
          <div class="form-column">
            <div class="form-column__item">
              <label>Status <strong>*</strong></label>
              <div class="form-control">
                <dx-select-box
                  [dataSource]="dataSource"
                  formControlName="WarehouseDocumentStatus"
                  valueExpr="Code"
                  displayExpr="Description"
                  itemTemplate="item"
                  [ngClass]="{
                    'input-required':
                      !form.controls.WarehouseDocumentStatus.valid && submitted
                  }"
                >
                  <div *dxTemplate="let data of 'item'">
                    <div
                      [innerHtml]="data.Description + ' (' + data.Code + ')'"
                    ></div>
                  </div>
                </dx-select-box>
              </div>
            </div>
          </div>

          <div class="form-column form-column__full-width">
            <div class="form-column__item">
              <label>{{ "offers.comment" | translate }}</label>
              <div class="form-control form-control__full-width">
                <app-description-dictionary
                  [dropDownBoxMode]="true"
                  type="K"
                  (onClosed)="cd.detectChanges()"
                  heightArea="230"
                  formControlName="Comment"
                  title="{{ 'offers.comment' | translate }}"
                  (onChoosed)="onStatusDescription($event)"
                >
                </app-description-dictionary>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="isVisible === false"
>
</wapro-keyboard-shortcuts>
